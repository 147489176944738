import { combineReducers } from "redux";

function sessionReducer(state = {}, action) {
  switch (action.type) {
    case "SESSION_INITIALIZE":
      return {
        ...state,
        id: action.payload.id,
        customerId: action.payload.customerId,
        conversationId: action.payload.conversationId,
        recipientId: action.payload.recipientId,
        responses: "",
        initialActionMessageSent: false,
      };
    case "FINISH_SESSION":
      return {
        ...state,
        archived: true,
      };
    case "SET_INITIAL_ACTION_MESSAGE_SENT":
      return {
        ...state,
        initialActionMessageSent: true,
      };
    default:
      return state;
  }
}

function messageReducer(state = [], action) {
  switch (action.type) {
    case "SESTEK_SUCCESS":
      return [...state, action.payload.response];
    default:
      return state;
  }
}

function responsesReducer(state = [], action) {
  if (action && action.type === "SESTEK_SUCCESS" && action.pushToResponses) {
    console.log(state);
    return state + action.payload.response.text + "/";
  } else {
    return state;
  }
}

function nameReducer(state = "", action) {
  switch (action.type) {
    case "SET_NAME":
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  messages: messageReducer,
  session: sessionReducer,
  responses: responsesReducer,
  name: nameReducer,
});
