import axios from "axios";
import history from "../history";

export const ACTION_TYPE_MESSAGES = {
  'cancel-policy': 'Poliçemi iptal etmek istiyorum',
};

export const BOT_TRIGGER_MESSAGES = [
  'What would you like to do?',
  'Size hangi konuda yardımcı olabilirim?',
];

const shouldTriggerActionMessage = (message) => {
  if (!message) return false;
  return BOT_TRIGGER_MESSAGES.some(triggerMessage => message.includes(triggerMessage));
};

export function sestekFetchDataSuccess(response, pushToResponses = true) {
  return {
    type: "SESTEK_SUCCESS",
    payload: { response },
    pushToResponses: pushToResponses,
  };
}

export function initializeSession(id, customerId, conversationId, recipientId) {
  return {
    type: "SESSION_INITIALIZE",
    payload: { id, customerId, conversationId, recipientId },
  };
}

export function initializeBotSession(
  id,
  customerId,
  conversationId,
  recipientId
) {
  return (dispatch) => {
    axios
      .post("/run", {
        text: "hi", // This is the kickoff message for Sestek API
        id,
        customerId,
        conversationId,
        recipientId,
      })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }

        return response;
      })
      .then((response) =>
        dispatch(sestekFetchDataSuccess(response.data, false))
      )
      .catch(console.error);
    dispatch(initializeSession(id, customerId, conversationId, recipientId));
  };
}

export function finishSession() {
  return {
    type: "FINISH_SESSION",
  };
}

export function redirectToChat() {
  return {
    type: "REDIRECT_TO_CHAT",
  };
}

export function setName(name) {
  return {
    type: "SET_NAME",
    payload: name,
  };
}

export function setInitialActionMessageSent() {
  return {
    type: "SET_INITIAL_ACTION_MESSAGE_SENT",
  };
}

const handleActionType = (dispatch, sessionData, hasInitialActionMessageSent) => {
  const urlParams = new URLSearchParams(window.location.search);
  const actionType = urlParams.get('actionType');

  if (actionType && !hasInitialActionMessageSent) {
    const message = ACTION_TYPE_MESSAGES[actionType];

    if (message) {
      dispatch(
        respond(
          message,
          sessionData.id,
          sessionData.customerId,
          sessionData.conversationId,
          sessionData.recipientId,
          message
        )
      );
      dispatch(setInitialActionMessageSent());
    }
  }
};

export function respond(
  value,
  id,
  customerId,
  conversationId,
  recipientId,
  text,
  isAnswer = true,
  pushToResponses = true
) {
  console.log("respond called");
  return (dispatch, getState) => {
    if (isAnswer) {
      dispatch(
        sestekFetchDataSuccess({ text: text, type: "answer" }, pushToResponses)
      );
    }
    axios
      .post("/run", {
        text: value,
        id,
        customerId,
        conversationId,
        recipientId,
      })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => {
        dispatch(sestekFetchDataSuccess(response.data, false));
        return response.data;
      })
      .then((data) => {
        if (data.text && shouldTriggerActionMessage(data.text)) {
          const state = getState();
          handleActionType(dispatch, { id, customerId, conversationId, recipientId }, state.session.initialActionMessageSent);
        }

        if (
          data.channelData &&
          data.channelData.CustomActionData &&
          data.channelData.CustomAction === "postBack" &&
          data.channelData.CustomActionData.indexOf("data:image") < 0 &&
          data.channelData.CustomActionData.indexOf("Name") < 0
        ) {
          dispatch(
            respond(
              data.channelData.CustomActionData,
              id,
              customerId,
              conversationId,
              recipientId,
              data.channelData.CustomActionData,
              false
            )
          );
          // else if (data.channelData && data.channelData.CustomActionData && data.channelData.CustomActionData !== "HangUp" && data.channelData.CustomActionData.indexOf("data:image") < 0 && data.channelData.CustomActionData.indexOf("Name") < 0) {
          //   dispatch(respond(data.channelData.CustomActionData, id, customerId, conversationId, recipientId, data.channelData.CustomActionData, false));
          //}
        } else if (
          data.channelData &&
          data.channelData.CustomActionData &&
          data.channelData.CustomActionData === "HangUp" &&
          data.channelData.CustomActionData.indexOf("data:image") < 0
        ) {
          dispatch(finishSession());
        } else if (
          data.channelData &&
          data.channelData.CustomAction &&
          data.channelData.CustomAction.indexOf("livechat") > -1
        ) {
          dispatch(finishSession());
          setTimeout(function () {
            history.push("/chat/");
          }, 3000);
        } else if (
          data.channelData &&
          data.channelData.CustomActionData &&
          data.channelData.CustomActionData.indexOf("Name") > -1
        ) {
          dispatch(setName(data.channelData.CustomActionData));
        }
      });
  };
}
