import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class Chat extends Component {
  isOperating = () => {
    const workingDays = [1, 2, 3, 4, 5];
    const morningShift = ["05:30", "09:00"];
    const afternoonShift = ["10:00", "13:30"];

    const d = new Date();
    const day = d.getUTCDay();
    let hour = d.getUTCHours();
    hour = hour >= 10 ? hour : "0".concat(hour);

    let minute = d.getUTCMinutes();
    minute = minute >= 10 ? minute : "0".concat(minute);

    if (!workingDays.includes(day)) {
      return false;
    }

    const now = hour + ":" + minute;

    if (
      (morningShift[0] <= now && morningShift[1] > now) ||
      (afternoonShift[0] <= now && afternoonShift[1] > now)
    ) {
      return true;
    }

    return false;
  };

  render() {
    if (!this.isOperating()) {
      return (
        <div className="office-hours">
          <div className="office-hours-message">
            Mesai saatleri dışında ve Resmi/İdari tatillerde müşteri
            temsilcisine yönlendirme yapamıyorum. <br />
            Mesai saatlerinde benimle tekrar irtibata geçerseniz sizi müşteri
            temsilcimize yönlendirebilirim. <br />
            Dilerseniz; <br />
            Talep ve önerileriniz için iletisim@axasigorta.com.tr <br />
            Hayat ve Emeklilik İşlemleriniz için emeklilik@axasigorta.com.tr
            adreslerimize de başvurabilirsiniz.
          </div>
        </div>
      );
    }

    const { responses, messages, session, name } = this.props;

    let chatLink =
      "https://bot.pisano.com.tr/web_feedback?node_id=e441a090-2396-47d0-a2bf-ffe5b41e34e9";

    if (messages && session && messages.length > 0 && session.archived) {
      let params = [];
      params.push({ question: "botkvkk", answer: "evet" });
      const ssn = responses
        .split("/")
        .find((r) => r.length === 11 && !Number.isNaN(parseInt(r)));
      const phone = responses
        .split("/")
        .find(
          (r) =>
            r.length === 10 && r.indexOf(".") < 0 && !Number.isNaN(parseInt(r))
        );
      ssn && params.push({ question: "bottckn", answer: ssn });
      phone && params.push({ question: "bottelefon", answer: phone });

      if (name && name.length > 0) {
        const splittedName = name.split("|");
        const firstName = splittedName[0].split(":")[1];
        const lastName = splittedName[1].split(":")[1];
        const fullName = firstName + " " + lastName;
        params.push({ question: "isim", answer: fullName });
      }

      const preloadedResponses = btoa(
        unescape(encodeURIComponent(JSON.stringify(params)))
      );
      chatLink += "&responses=" + preloadedResponses;
    }

    return <iframe title="Canlı Destek" src={chatLink}></iframe>;
  }
}

const mapStateToProps = (state) => {
  return {
    responses: state.responses,
    session: state.session,
    messages: state.messages,
    name: state.name,
  };
};

export default withRouter(connect(mapStateToProps)(Chat));
